import { Component } from '../Component'
import classPrefix from "../classPrefix";

export interface SiteSwitcherProps {}

export class SiteSwitcher extends Component<SiteSwitcherProps> {
	isExpanded = false
	eventTriggeredByMouse = false

	init() {
		this.listenOn('click', `.${classPrefix}siteSwitcher-active`, this.onClick)
		this.listenOn('mousedown', `.${classPrefix}siteSwitcher-active`, this.onMouseDown)
		this.listenOn('focusin', `.${classPrefix}siteSwitcher-active`, this.onFocus)
		this.listen('focusout', this.onBlur)

		let lastScrollY = 0
		window.addEventListener('scroll', () => {
			if (lastScrollY < window.scrollY && this.isExpanded) {
				this.close()
			}
			lastScrollY = window.scrollY
		})
	}

	onMouseDown() {
		this.eventTriggeredByMouse = true
		requestAnimationFrame(() => {
			this.eventTriggeredByMouse = false
		})
	}

	onClick() {
		if (this.isExpanded) {
			this.close()
		} else {
			this.open()
		}
	}

	onFocus() {
		if (!this.eventTriggeredByMouse) {
			this.open()
		}
	}

	onBlur() {
		const lastState = this.isFocusWithin()
		requestAnimationFrame(() => {
			if (lastState === this.isFocusWithin()) {
				this.close()
			}
		})
	}

	open() {
		if (!this.isExpanded) {
			this.isExpanded = true
			this.el.classList.add('is-expanded')
			window.addEventListener('keydown', this.onKey)
		}
	}

	close() {
		if (this.isExpanded) {
			this.isExpanded = false
			this.el.classList.remove('is-expanded')
			window.removeEventListener('keydown', this.onKey)
		}
	}

	onKey(event: KeyboardEvent) {
		if (event.code === 'Escape') {
			this.close()
		}
	}

	isFocusWithin() {
		const { activeElement } = document
		return activeElement instanceof HTMLElement && this.el.contains(activeElement)
	}
}
