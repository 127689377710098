import classPrefix from "./classPrefix";

export default `
<ul class="${classPrefix}siteSwitcher-list">
    <li class="${classPrefix}siteSwitcher-item">
        <a class="${classPrefix}siteSwitcher-link" href="https://krizovatka.skaut.cz/">Křižovatka</a>
    </li>
	<li class="${classPrefix}siteSwitcher-item">
		<a class="${classPrefix}siteSwitcher-link" href="https://zpravodajstvi.skaut.cz/">Zpravodajství</a>
	</li>
    <li class="${classPrefix}siteSwitcher-item">
        <a class="${classPrefix}siteSwitcher-link" href="https://kurzy.skaut.cz/">Vzdělávací akce</a>
    </li>
	<li class="${classPrefix}siteSwitcher-item">
		<a class="${classPrefix}siteSwitcher-link" href="https://zakladny.skaut.cz/">Základny</a>
	</li>
    <li class="${classPrefix}siteSwitcher-item">
        <a class="${classPrefix}siteSwitcher-link" href="https://www.skaut.cz/">Skaut.cz</a>
    </li>
</ul>
`
