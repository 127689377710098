import headerSource from "./headerSource";
import footerSource from "./footerSource";
import '../styles/main.sass';
import { SiteSwitcher } from "./components/SiteSwitcher";
import classPrefix from "./classPrefix";

export const initSkautHeader = () => {
	const header = document.querySelector<HTMLElement>(`.${classPrefix}siteSwitcher`)

	if (header !== null) {
		header.insertAdjacentHTML('beforeend', headerSource)

		// Remove self
		const links = header.querySelectorAll<HTMLAnchorElement>(`.${classPrefix}siteSwitcher-link`)
		for (const link of Array.from(links)) {
			if (window.location.href.startsWith(link.href)) {
				link.remove()
			}
		}

		// Make it interactive
		new SiteSwitcher(header, {}).init()
	} else {
		throw new Error('No header was found')
	}
}

export const initSkautFooter = () => {
	const footer = document.querySelector('.skaut-footer')
	if (footer !== null) {
		const vendor = footer.getAttribute('data-vendor') ?? undefined
		footer.innerHTML = footerSource(vendor)
	} else {
		throw new Error('No footer was found')
	}
}
