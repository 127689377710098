export abstract class Component<P, E extends HTMLElement = HTMLElement> {
	constructor(protected readonly el: E, protected readonly props: P) {}

	abstract init(): void

	protected listen<T extends keyof HTMLElementEventMap>(
		type: T,
		listener: (event: HTMLElementEventMap[T]) => unknown,
		options?: boolean | AddEventListenerOptions,
	) {
		this.el.addEventListener<T>(type, e => listener.call(this, e), options)
	}

	protected listenOn<T extends keyof HTMLElementEventMap>(
		type: T,
		delegateSelector: string,
		listener: (event: HTMLElementEventMap[T], delegateTarget: HTMLElement) => unknown,
		options?: boolean | AddEventListenerOptions,
	) {
		this.el.addEventListener<T>(type, this.delegate(delegateSelector, listener), options)
	}

	protected delegate<E extends Event>(delegateSelector: string, listener: (event: E, delegateTarget: HTMLElement) => unknown) {
		return (event: E) => {
			for (let target = event.target; target instanceof HTMLElement && target !== this.el; target = target.parentElement) {
				if (target.matches(delegateSelector)) {
					return listener.call(this, event, target)
				}
			}
		}
	}
}
