import facebookIcon from "./icons/facebook";
import instagramIcon from "./icons/instagram";
import youtubeIcon from "./icons/youtube";
import twitterIcon from "./icons/twitter";
import logo from "./icons/logo";

export default (vendor?: string) => `
	<div class="skaut-footer-in">
		<div class="skaut-footer-main">
			<ul class="skaut-footer-mainNavigation">
				<li>
					<a href="https://krizovatka.skaut.cz/">
						Křižovatka
					</a>
				</li>
				<li>
					<a href="https://zpravodajstvi.skaut.cz">
						Zpravodajství
					</a>
				</li>
				<li>
					<a href="https://kurzy.skaut.cz/">
						Vzdělávací akce
					</a>
				</li>
				<li>
					<a href="https://zakladny.skaut.cz">
						Základny
					</a>
				</li>
				<li>
					<a href="https://is.skaut.cz/">
						SkautIS
					</a>
				</li>
				<li>
					<a href="https://www.skaut.cz/">
						Skaut.cz
					</a>
				</li>
			</ul>

			<ul class="skaut-footer-column">
				<li>
					<h2 class="skaut-footer-sectionTitle">
						Projekty
					</h2>
				</li>
				<li>
					<a href="https://www.junshop.cz/" class="skaut-footer-basicLink">
						JUNSHOP
					</a>
				</li>
				<li>
					<a href="https://tdc.skaut.cz/" class="skaut-footer-basicLink">
						Skautské vydavatelství
					</a>
				</li>
				<li>
					<a href="https://www.skautskyinstitut.cz/" class="skaut-footer-basicLink">
						Skautský institut
					</a>
				</li>
				<li>
					<a href="https://zavody.skaut.cz/" class="skaut-footer-basicLink">
						Závody
					</a>
				</li>
			</ul>

			<ul class="skaut-footer-column">
				<li>
					<h2 class="skaut-footer-sectionTitle">
						<a href="https://casopisy.skaut.cz/">
							Časopisy
						</a>
					</h2>
				</li>
				<li>
					<a href="https://casopis.skauting.cz/" class="skaut-footer-basicLink">
						Skauting
					</a>
				</li>
				<li>
					<a href="https://kmen.skauting.cz/" class="skaut-footer-basicLink">
						Roverský kmen
					</a>
				</li>
				<li>
					<a href="https://benjamina.skaut.cz/" class="skaut-footer-basicLink">
						Ben Já Mína
					</a>
				</li>
				<li>
					<a href="https://casopisy.skaut.cz/" class="skaut-footer-basicLink">
						Archiv časopisů
					</a>
				</li>
			</ul>

			<ul class="skaut-footer-column">
				<li>
					<h2 class="skaut-footer-sectionTitle">
						Zdroje
					</h2>
				</li>
				<li>
					<a href="https://www.facebook.com/SkautInfo" class="skaut-footer-basicLink">
						SkautInfo
					</a>
				</li>
				<li>
					<a href="https://www.facebook.com/groups/skautforum" class="skaut-footer-basicLink">
						SkautFórum
					</a>
				</li>
				<li>
					<a href="https://radce.skaut.cz/" class="skaut-footer-basicLink">
						Rádcovský rozcestník
					</a>
				</li>
				<li>
					<a href="https://www.rovernet.cz/" class="skaut-footer-basicLink">
						Rovernet
					</a>
				</li>
				<li>
					<a href="https://kmendospelych.skauting.cz/" class="skaut-footer-basicLink">
						Kmen dospělých
					</a>
				</li>
				<li>
					<a href="https://vodni.skauting.cz/" class="skaut-footer-basicLink">
						Vodní skauti
					</a>
				</li>
			</ul>

			<ul class="skaut-footer-column">
				<li>
					<h2 class="skaut-footer-sectionTitle">
						Sleduj nás
					</h2>
				</li>
				<li>
					<a href="https://www.facebook.com/skaut" class="skaut-footer-basicLink">
						<span class="skaut-footer-basicLink-icon">${facebookIcon}</span>
						Facebook
					</a>
				</li>
				<li>
					<a href="https://instagram.com/skaut/" class="skaut-footer-basicLink">
						<span class="skaut-footer-basicLink-icon">${instagramIcon}</span>
						Instagram
					</a>
				</li>
				<li>
					<a href="https://www.youtube.com/user/czskaut" class="skaut-footer-basicLink">
						<span class="skaut-footer-basicLink-icon">${youtubeIcon}</span>
						YouTube
					</a>
				</li>
				<li>
					<a href="https://www.twitter.com/skaut" class="skaut-footer-basicLink">
						<span class="skaut-footer-basicLink-icon">${twitterIcon}</span>
						Twitter
					</a>
				</li>
			</ul>
		</div>
		<hr class="skaut-footer-divider">
		<div class="skaut-footer-license">
			<div class="skaut-footer-license-text">
				<p>
					Provozuje <a class="skaut-footer-license-link" target="_blank" rel="noreferrer noopener" href="https://www.skaut.cz/">Junák - český skaut, z. s.</a>: obsah publikován pod licencí Creative Commons <a class="skaut-footer-license-link" target="_blank" rel="noreferrer noopener" href="https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode">BY-NC-SA</a>
				</p>
				${vendor ? `<p>${vendor}</p>` : ''}
			</div>
			<a class="skaut-footer-license-logo" href="https://www.skaut.cz/">
				${logo}
			</a>
		</div>
	</div>

`;
